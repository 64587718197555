.header-section {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
}

.header-section.header-fixed {
  position: fixed;
  background-color:#2d0572;
  width: 100%;
  box-shadow: 0px 3px 10px 0px rgb(0 0 0 / 30%);
}
.fadeInDown {
  animation-name: fadeInDown;
}
.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}
@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
.container-fluid {
  margin-top: -8px;
  width: 100%;
  height: 90px;
  padding-left: 90px;
  padding-right: 90px;
  font-size: 18px;
  color: white;
  background-color: transparent;
}

.nav {
  padding-right: 49px;
  color: white;
}
.nav:hover {
  color: #00ff66;
}

/* .container {
  width: 129px;
  height: 39px;
} */

.nav-link {
  color: white;
}

.nav-link:hover {
  color: #ffd133;
}

.active-self {
  color: #ffd133 !important;
}

.collapse {
  width: 100px;
  height: 52px;
}

.pi {
  margin-right: 12px;
}

.login {
  color: white;
  width: 400px;
  display: flex;
  padding-top: 39px;
  padding-bottom: 39px;
  justify-content: center;
  background-color: #070044;
}

.btn1 {
  margin: 0;
  padding: 8px;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  color: white;
}

.l {
  text-decoration: none;
}

.btn2 {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  display: flex;
  align-items: center;
  border-radius: 50px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  color: white;
  background-color: #7924c7;
}

.dropdown-menu {
  background-color: #070044;
  /* color: white !important; */
}

.dropdown-item {
  color: white !important;
}
.dropdown-item:hover {
  background-color: #00ff66;
}
