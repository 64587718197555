* {
  font-family: var(--body-font);
  font-weight: bold;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

body {
  width: 100%;
  background-color: #070044;
  font-family: var(--body-font);
  font-size: 18px;
  line-height: 30px;
  padding: 0;
  margin: 0;
  font-weight: 400;
  overflow-x: hidden;
}
