.mainSection {
  width: 100%;
}
.firstSection {
  background-position: center;
  background-color: #2b0d88;
  background-image: url(../../public/image_react/index-bg.png);
  background-repeat: no-repeat;
}
.light {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.leftLight {
  display: flex;
  animation: zoomInOut 10s linear infinite;
}

@keyframes zoomInOut {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}
.rightLight {
  display: flex;
  animation: spin 30s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.firstSectionCenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.firstSectionCenterL1 {
  margin-top: 147px;
  color: #00ff66;
  font-size: 32px;
  font-weight: 600;
}
.firstSectionCenterL2 {
  font-size: 76px;
  padding-top: 50px;
  font-weight: 700;
  color: white;
}
.firstSectionCenterL3 {
  font-size: 24px;
  padding-top: 50px;
  font-weight: 400;
  color: white;
}
.lightAnimation {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.leftSideBanner {
  padding-right: 1000px;
  margin-top: -200px;
  animation: ripple2Ani 4s linear infinite;
}
@keyframes ripple2Ani {
  0% {
    transform: translate(0px, 0px);
  }
  33% {
    transform: translate(-5px, -5px);
  }
  66% {
    transform: translate(5px, 5px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}

.rightSideBanner {
  margin-top: -231px;
  animation: rippleAni 3s linear infinite;
}
@keyframes rippleAni {
  0% {
    transform: translate(0px, 0px);
  }
  33% {
    transform: translate(5px, -5px);
  }
  66% {
    transform: translate(-5px, 5px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}
.firstSectionLast {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 45px;
}
.firstSectionButton {
  padding: 8px;
  padding-left: 40px;
  padding-right: 40px;
  border-radius: 50px;
  background-color: #7924c7;
  font-size: 18px;
  line-height: 30px;
  color: white;
  border: none;
}
.firstSectionButton:hover {
  background-color: transparent;
  color: white;
  border: 1px solid #7924c7;
}
.firstSectionLastBanner {
  animation: ripple2Ani 3s linear infinite;
}
@keyframes ripple2Ani {
  0% {
    transform: translate(0px, 0px);
  }
  33% {
    transform: translate(-5px, -5px);
  }
  66% {
    transform: translate(5px, 5px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}
.play {
  width: 30px;
  height: 30px;
  background: #58b463;
  border-radius: 50%;
}
.play-icon {
  margin-left: 12px;
}

.play::before {
  position: absolute;
  content: "";
  background: #58b463;
  opacity: 0.2;
  border-radius: 50%;
  width: 65px;
  height: 65px;
  margin-top: -16px;
  margin-left: -16px;
}
.heading {
  padding-top: 200px;
}