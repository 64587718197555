body {
  background-color: #070044;
}
.firstSection {
  background-image: url(../../public/image_react/index-bg.png);
  background-position: top;
  background-repeat: no-repeat;
  background-color: #2f007f;
}
.imageSection {
  display: flex;
  justify-content: space-between;
  padding-top: 300px;
  padding-bottom: 100px;
}
.imageSection1 {
  animation: ripple2Ani 4s linear infinite;
}
@keyframes ripple2Ani {
  0% {
    transform: translate(0px, 0px);
  }
  33% {
    transform: translate(-5px, -5px);
  }
  66% {
    transform: translate(5px, 5px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}
.imageSection2 {
  animation: rippleAni 3s linear infinite;
}
@keyframes rippleAni {
  0% {
    transform: translate(0px, 0px);
  }
  33% {
    transform: translate(5px, -5px);
  }
  66% {
    transform: translate(-5px, 5px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}
.heading {
  font-size: 76px;
  font-weight: 600;
  color: white;
  padding-bottom: 100px;
}

.condition {
  font-size: 18px;
  font-weight: 400;
  color: white;
  padding-bottom: 100px;
}
.secondSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: #070044;
  padding-bottom: 100px;
  padding-top: 50px;
}
.secondHeading {
  padding-top: 100px;
}
.terms {
  font-size: 18px;
  font-weight: 400;
}
.terms p {
  color: white;
  opacity: 0.8;
}
.terms__service {
  font-size: 22px;
  font-weight: 300;
  color: white;
}
.condition {
  font-size: 19px;
  font-weight: bold;
}
.card {
  margin-top: 200px;
}
.contactBox {
  border: 1px solid black;
  margin-top: 150px;
  background-color: white;
}
/* .textArea {
  border-radius: 30px;
  padding-left: 30px;
  width: 345px;
  padding: 10px;
} */
.headingContact {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  padding-top: 100px;
}
/* .head {
  color: #ffd113;
  font-size: 20px;
  font-weight: 700;
} */
/* .secondHead {
  font-size: 2.25rem;
  font-weight: 600;
} */
.formContact {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 20px;
}
/* .message {
  width: 474px;
  height: 100px;
} */
