.Home {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* background-position-x: 56%; */
  /* background-position-y: -20%; */
  width: 100%;
  margin-top: -187px;
  background-repeat: no-repeat;
  /* background-color: #361c86; */
  /* background-position: center;
  background-image: url(../../public/image_react/bgi_cricket_stadium.png);
  background-size: contain; */
}
.Home::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  /* background-color: rgba(54, 28, 134, 0.8); */
  background-color: rgba(248, 247, 216, 0.7);
  background-image: url(../../public/image_react/bgi_cricket_stadium.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center; 
  z-index: -1;
  opacity: .3;
}

.first {
  color: #ffd133;
  font-size: 24px;
  margin-top: -188px;
}

.second {
  margin-top: 39px;
  font-size: 46px;
  color: white;
}

.third {
  margin-top: 44px;
  font-size: 24px;
  margin-bottom: 45px;
  color: white;
}
.button {
  border-radius: 50px;
  border: none;
  background-color: #7924c7;
  color: white;
  margin: auto;
  padding: 10px 30px;
}

/* .button {
  margin-left: -78px;
  margin-top: 47px;
  border-radius: 50px;
  padding: 8px;
  padding-left: 40px;
  padding-right: 40px;
  background-color: #7924c7;
  font-size: 18px;
  line-height: 30px;
  color: white;
  border: none;
}

.button:hover {
  background-color: transparent;
  color: white;
  border: 1px solid #7924c7;
} */

.d-flex {
  margin: 5px;
  color: white;
}

.light {
  padding-top: 144px;
  margin-left: -1004px;
  margin-top: 147px;
  left: 0;
  animation: zoomInOut 10s linear infinite;
}

@keyframes zoomInOut {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}

.lightE {
  margin-left: 1027px;
  margin-top: -277px;
  animation: spin 30s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.banner1 {
  margin-top: -63px;
  margin-left: -977px;
  animation: ripple2Ani 4s linear infinite;
  /* display: none; */
  /* max-width: 100px; */
}
@keyframes ripple2Ani {
  0% {
    transform: translate(0px, 0px);
  }
  33% {
    transform: translate(-5px, -5px);
  }
  66% {
    transform: translate(5px, 5px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}
.banner2 {
  margin-left: 974px;
  margin-top: -68px;
  right: 10%;
  top: calc(35%);
  animation: rippleAni 3s linear infinite;
}
@keyframes rippleAni {
  0% {
    transform: translate(0px, 0px);
  }
  33% {
    transform: translate(5px, -5px);
  }
  66% {
    transform: translate(-5px, 5px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}

.banner {
  margin-top: 132px;
  width: 100%;
}

.banner3 {
  margin-top: 403px;
  padding-top: 11px;
  width: 100%;
}

.banner4 {
  margin-left: -381px;
  margin-top: 4px;
  animation: ripple2Ani 3s linear infinite;
}
@keyframes ripple2Ani {
  0% {
    transform: translate(0px, 0px);
  }
  33% {
    transform: translate(-5px, -5px);
  }
  66% {
    transform: translate(5px, 5px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}
.play-icon {
  margin-left: 12px;
  margin-top: -5px;
}
.play {
  width: 30px;
  height: 30px;
  background: #58b463;
  border-radius: 50%;
  margin-left: 256px;
  margin-top: -21px;
}
.play::before {
  position: absolute;
  content: "";
  background: #58b463;
  opacity: 0.2;
  border-radius: 50%;
  width: 65px;
  height: 65px;
  margin-top: -16px;
  margin-left: -17px;
}
.b {
  padding-top: 0px;
  margin-top: -504px;
  width: 100%;
}

.b1 {
  font-size: 24px;
  font-weight: 600;
  color: #ffd133;
  padding-top: 70px;
}

.b2 {
  margin-top: 10px;
  font-size: 57px;
  font-weight: 700;
}

.i1 {
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 0px;
  width: 100%;
  background-color: #070044;
}

.icon {
  display: flex;
  flex-direction: row;
  background-color: #070044;
  width: 100%;
  padding: 102px 40px;
  align-items: center;
  justify-content: space-around;
}

.icon > div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.h4 {
  padding-left: 12px;
  color: white;
  font-weight: 600;
  visibility: visible;
  animation-name: fadeInUp;
}
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

p {
  color: #5ac4ff;
}

.top-bg {
  display: grid;
  justify-content: center;
  align-items: center;
  background-image: url(../../public/image2_react/top-bg.png);
  width: 100%;
  background-position: center;
  position: relative;
  height: 400px;
}

.topBg {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.firstLine {
  padding-top: 116px;
  color: #ffd133;
  font-size: 24px;
  font-weight: 600;
}

.secondLine {
  color: white;
  font-size: 57px;
  font-weight: 700;
  text-align: center;
}

.thirdLine {
  color: white;
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 35px;
}

.forthLine {
  color: white;
  font-size: 18px;
  font-weight: 400;
  padding-left: 218px;
  margin-bottom: 235px;
}

.thirdSection {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  /* margin-left: 110px; */
  width: 100%;
  position: absolute;
  top: 60%;
  margin-top: 30px;
}
.trending_predictors_parent {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 30px;
}

.c1 {
  width: 306px;
  height: 400px;
  margin-right: 25px;
  border-radius: 15px;
  background-color: #221a62;
}

.top-bg-1 {
  margin-top: 0px;
  width: 306px;
}

.top-profile-1 {
  width: 80px;
  border-radius: 50%;

  margin-left: 121px;
  margin-top: -42px;
}

.predictors_card_top {
  height: 96px;
}

.predictors_card_top > image {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.predictors_avatar {
  height: 100px !important;
  width: 100px !important;
  position: absolute;
  left: 50%;
  top: -50%;
  transform: translate(-50%);
}

.gold {
  margin-left: -22px;
  margin-top: 6px;
}

.card-body {
  margin-top: 70px;
}

.card-title {
  font-size: 24px;
  color: white;
  text-align: center;
}

.card-stats {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  padding: 10px 20px;
  align-items: center;
}

.card-stat {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.m {
  margin: auto;
  max-width: 160px;
  display: block;
  border-radius: 50px;
  padding: 12px;
  padding: 10px 30px;
  margin-top: 8px;
  color: white;
  background-color: #7924c7;
}
.m:hover {
  background-color: transparent;
  color: white;
  border: 1px solid #7924c7;
}

.follow {
  padding-left: 30px;
  padding-top: 27px;
}

.c1Text1 {
  color: white;
  padding-left: 62px;
  margin-top: -34px;
}

.coll {
  padding-left: 89px;
  margin-top: -42px;
  margin-bottom: 89px;
  color: #5ac4ff;
}

.following {
  padding-left: 116px;
  margin-top: -262px;
}

.c1Text2 {
  padding-left: 149px;
  margin-top: -169px;
}

.c2Text1 {
  color: #00ff66;
  padding-top: 38px;
  padding-left: 34px;
}

.c2Text2 {
  color: white;
  padding-left: 50px;
}

.c2Text3 {
  color: #5ac4ff;
  padding-left: 200px;
  margin-top: -61px;
}

.c2Text4 {
  color: white;
  padding-left: 211px;
}
.mainBtn {
  display: flex;
  justify-content: center;
  align-items: center;
}
.main {
  background-color: #070044;
  color: white;
  margin-top: 99px;
  padding-left: 31px;
  padding-right: 30px;
  padding-bottom: 15px;
  padding-top: 15px;
  border-radius: 10px;
  border: 1px solid white;
}
.main:hover {
  background-color: #7924c7;
  color: white;
  border: 1px solid #7924c7;
}
.fl {
  background-color: #070044;
  width: 100%;
  padding-top: 400px;
}

.fl1 {
  display: flex;
  justify-content: center;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 23px;
  color: #ffd133;
}

.fl2 {
  display: flex;
  justify-content: center;
  color: white;
  font-size: 57px;
  font-weight: 700;
  margin-top: 41px;
  margin-bottom: 38px;
}

.fl3 {
  display: flex;
  justify-content: center;
  color: white;
}

.fl4 {
  display: flex;
  justify-content: center;
  color: white;
}

.fl5 {
  display: flex;
  justify-content: center;
  color: white;
}

.sec {
  width: 100%;
}

.forthSection {
  width: 100%;
  background-color: #070044;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 50px;
}

.how-1 {
  display: flex;
  flex-direction: column;
  align-items: baseline;
}

.ro {
  display: flex;
  align-items: center;
  font-size: 43px;
  font-weight: 600;
  width: 90px;
  height: 90px;
  border-radius: 50%;
  color: white;
  margin-top: 90px;
  border: 7px solid #070044;
  background-color: #7924c7;
  justify-content: center;
}

.how1 {
  object-fit: contain;
}

.h1 {
  font-size: 43px;
  font-weight: 700;
  color: white;
}

.h2 {
  color: white;
  font-size: 18px;
  font-weight: 400;
}

.h3 {
  color: white;
  font-size: 18px;
  font-weight: 400;
}

.h5 {
  color: white;
  font-size: 18px;
  font-weight: 400;
}

.one {
  background-color: #070044;
  padding-left: 232px;
}

.fifthSection {
  /* background-color: #070044; */
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.how-2 {
  display: flex;
  flex-direction: column;
  margin-right: 226px;
  align-items: baseline;
  object-fit: contain;
}
.ro1 {
    position: absolute;
    top: 50%;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 43px;
    font-weight: 600;
    width: 90px;
    height: 90px;
    border-radius: 50%;
    color: white;
    border: 7px solid #070044;
    background-color: #7924c7;
    transform: translate(100%, -50%);
}

.ro1 p {
  margin: 0;
  color: white;
}

.ro2 {
  position: absolute;
  top: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 43px;
  font-weight: 600;
  width: 90px;
  height: 90px;
  border-radius: 50%;
  color: white;
  border: 7px solid #070044;
  background-color: #7924c7;
  transform: translate(-100%, -50%);
}
.ro2 p {
  margin: 0;
  color: white;
}

/* .how2 {
  background-color: #070044;
  margin-top: -56px;
  width: 100%;
  height: 100%;
} */

.two {
  background-color: #070044;
  margin-top: 6px;
  padding-left: 298px;
}

.sixthSection {
  width: 100%;
  background-color: #070044;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.cou {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-left: 20px;
}
.cou p {
  color: white
}
.cou h4 {
  padding: 0;
}
.ro3 {
  position: absolute;
  top: 50%;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 43px;
  font-weight: 600;
  width: 90px;
  height: 90px;
  border-radius: 50%;
  color: white;
  border: 7px solid #070044;
  background-color: #7924c7;
  transform: translate(100%, -50%);
}

.how-3 {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  object-fit: contain;
  margin-right: 80px;
}

/* .how3 {
  background-color: #070044;
  margin-top: 8px;
} */

.seventhSection {
  background-image: url(../../public/image2_react/join-us-bg.png);
  width: 100%;
  margin-top: 100px;
}

.line1 {
  font-size: 24px;
  font-weight: 600;
  color: #ffd133;
  margin-bottom: 31px;
}

.line2 {
  color: white;
  font-size: 57px;
  font-weight: 700;
  margin-bottom: 57px;
}

.line3 {
  font-size: 18px;
  font-weight: 400;
  color: white;
}

.line4 {
  font-size: 18px;
  font-weight: 400;
  color: white;
}

.s {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 200px;
}

.box {
  width: 855px;
  border-radius: 22px;
  height: 270px;
  background-color: #5732c6;
  margin: auto;
  margin-top: -139px;
}

.download {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: auto;
  width: 80%;
  flex-direction: row;
}

.join {
  display: flex;
  justify-content: center;
  font-size: 32px;
  font-weight: 700;
  padding-top: 50px;
  padding-bottom: 50px;
  color: white;
}

.choose {
  border: 1px solid #7924c7;
  background-color: #7924c7;
  border-radius: 50px;
  color: white;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-right: 55px;
  font-size: 18px;
  font-weight: 600;
  margin-left: 191px;
  margin-bottom: 100px;
}
.choose:hover {
  background-color: transparent;
  color: white;
  border: 1px solid #7924c7;
}
.become {
  border: 1px solid #7924c7;
  color: white;
  border-radius: 50px;
  padding-right: 30px;
  padding-left: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 100px;
}
.become:hover {
  background-color: #7924c7;
  color: white;
  border: 1px solid #7924c7;
}
.eighthSection {
  background-color: #070044;
  width: 100%;
  margin-top: 50px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.e1 {
  font-size: 57px;
  font-weight: 700;
  color: white;
  margin-left: 92px;
  margin-top: 83px;
}

.e2 {
  font-size: 18px;
  font-weight: 400;
  color: white;
  margin-left: 94px;
  padding-top: 17px;
}

.e3 {
  font-size: 57px;
  font-weight: 700;
  color: white;
  margin-top: 42px;
  margin-bottom: 36px;
}

.e4 {
  font-size: 57px;
  font-weight: 700;
  color: white;
  margin-bottom: 18px;
}

.e5 {
  font-size: 18px;
  font-weight: 400;
  color: white;
}

.tips1 {
  display: flex;
  border-radius: 50%;
  margin-left: 52px;
  margin-top: 18px;
  border: 2px solid #c2a9ff;
}

.gold1 {
  display: flex;
  margin-left: 95px;
  margin-top: -21px;
}

.review {
  display: flex;
  flex-direction: row;
  margin-left: 34px;
  margin-top: 12px;
}

.star {
  width: 21px;
  display: flex;
  flex-direction: row;
}

.lf1 {
  color: white;
  margin-top: -128px;
  margin-left: 224px;
  font-size: 16px;
  font-weight: 400;
}

.lef3 {
  color: white;
  margin-top: -129px;
  margin-left: 223px;
  font-size: 16px;
  font-weight: 400;
}

.lf2 {
  color: #00ff66;
  font-size: 18px;
  font-weight: 600;
  margin-top: -33px;
  margin-left: 231px;
}

.l2 {
  color: #00ff66;
  font-size: 18px;
  font-weight: 600;
  margin-top: -33px;
  margin-left: 231px;
}

.l3 {
  color: #00ff66;
  font-size: 18px;
  font-weight: 600;
  margin-top: -33px;
  margin-left: 229px;
}

.l4 {
  color: #00ff66;
  font-size: 18px;
  font-weight: 600;
  margin-top: -33px;
  margin-left: 229px;
}

.lf3 {
  color: #5ac4ff;
  font-size: 16px;
  font-weight: 600;
  margin-left: 184px;
  margin-top: 17px;
}

.lf4 {
  color: #b2b4e1;
  font-size: 16px;
  font-weight: 400;
  margin-left: 184px;
  margin-top: 7px;
}

.foot {
  margin-left: 189px;
  margin-top: -193px;
}

.flag {
  margin-left: 180px;
  margin-top: 15px;
}

.right1 {
  border-radius: 20px;
  border: 1px solid #5e549a;
  width: 90px;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #070044;
  font-size: 18px;
  font-weight: 400;
  color: white;
  margin-left: 695px;
  margin-top: -160px;
}

.tips-1 {
  margin-left: 699px;
  margin-top: 20px;
  border-radius: 9px;
  background-color: #19115f;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.See {
  color: white;
  margin-top: 36px;
  margin-left: 421px;
  padding-bottom: 10px;
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  border: 1px solid #5e549a;
  border-radius: 10px;
}
.See:hover {
  background-color: #7924c7;
  color: white;
  border: 1px solid #7924c7;
}
.upcoming_matches_header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.match_container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
}

.match {
  display: flex;
  flex-direction: column;
  width: 550px;
  height: min-content;
  margin-top: 12px;
  border-radius: 10px;
  background-color: #221a62;
  border: 1px solid #5e549a;
  justify-content: space-around;
}

.match_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
}

.match_teams {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  background: #2d237b;
  border-radius: 12px;
  margin: 0px 20px 0px 20px;
  padding: 10px 10px;
}
.match_teams div {
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0;
}

.match_series {
  color: #c2a9ff;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  margin: 0;
  padding: 10px 0;
}

.nineSection {
  background-color: #2d0572;
  margin-top: 117px;
  width: 100%;
}

.nineHead {
  background-color: #2d0572;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.nineL1 {
  padding-top: 138px;
  color: #ffd133;
  font-size: 24px;
  font-weight: 600;
}

.nineL2 {
  margin-top: 26px;
  margin-bottom: 29px;
  font-size: 57px;
  font-weight: 700;
  color: white;
}

.nineL3 {
  font-size: 18px;
  font-weight: 400;
  color: white;
}

.nineL4 {
  font-size: 18px;
  font-weight: 400;
  color: white;
  margin-bottom: 70px;
}

.why1 {
  padding-top: 14px;
}

.nineSec {
  width: 650px;
  border-bottom: 1px solid #5b29b1;
  display: flex;
  padding: 5px 0
}

.nineSecL1 {
  color: white;
  font-size: 32px;
  font-weight: 600;
}

.nineSecL2 {
  color: white;
  font-size: 18px;
  font-weight: 400;
}

.nineSecL3 {
  color: white;
  font-size: 18px;
  font-weight: 400;
}
/* .nineSecL4 {
  color: white;
  font-size: 18px;
  font-weight: 400;
  margin-top: 5px;

  margin-left: 94px;
}
.nineThirdL1 {
  color: white;
  font-size: 32px;
  font-weight: 600;
  margin-top: -93px;
  margin-left: 92px;
}

.nineThirdL2 {
  color: white;
  font-size: 18px;
  font-weight: 400;
  margin-left: 91px;
  margin-top: -10px;
}

.nineThirdL3 {
  color: white;
  font-size: 18px;
  font-weight: 400;
  margin-left: 90px;
}

.nineForL1 {
  color: white;
  font-size: 32px;
  font-weight: 600;
  margin-top: -93px;
  margin-left: 90px;
}

.nineForL2 {
  color: white;
  font-size: 18px;
  font-weight: 400;
  margin-left: 90px;
  margin-top: -10px;
}

.nineForL3 {
  color: white;
  font-size: 18px;
  font-weight: 400;
  margin-left: 90px;
} */

.nineFifthSec {
  width: 650px;
  height: 150px;
  margin-top: 34px;
}

/* .nineFifthL1 {
  color: white;
  font-size: 32px;
  font-weight: 600;
  margin-top: -93px;
  margin-left: 92px;
}

 .nineFifthL2 {
  color: white;
  font-size: 18px;
  font-weight: 400;
  margin-left: 92px;
  margin-top: -10px;
}

.nineFifthL3 {
  color: white;
  font-size: 18px;
  font-weight: 400;
  margin-left: 92px;
}  */

.why-bg {
  margin-left: 759px;
  margin-top: -681px;
}

.why-bi-bg {
  width: 716px;
  height: 708px;
  margin-bottom: 112px;
}

.tenSection {
  background-color: #070044;
}
.tenSec {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.tenL1 {
  padding-top: 165px;
  margin-bottom: 28px;
  font-size: 24px;
  font-weight: 600;
  color: #ffd133;
}

.tenL2 {
  font-size: 57px;
  margin-bottom: 28px;
  font-weight: 700;
  color: white;
}

.tenL3 {
  font-size: 18px;
  font-weight: 400;
  color: white;
}

.tenL4 {
  font-size: 18px;
  font-weight: 400;
  color: white;
}

.winningSection {
  padding-top: 75px;
  background-color: #070044;
  display: grid;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.trending_players_match {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  grid-gap: 20px;
  min-height: fit-content;
}

.trending_players_title {
  text-align: center;
  background-color: #3a2fa4;
  border-radius: 12px 12px 0 0;
}
.trending_players_title p {
  margin: 0;
  padding: 10px 0;
  color: white;
}
.trending_players_fantastic_top_container {
  padding: 15px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}
.trending_players_fantastic_top {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}
.trending_players_fantastic_top p {
  margin: 0;
  color: white;
  font-size: 14.5px;
}
.trending_predictors_pitch_report {
  padding: 15px;
  display: flex;
  justify-content: space-around;
}
.trending_predictors_pitch_report p {
  margin: 0;
  color: white;
  font-size: 14.5px;
}

.winningFirst {
  width: 1264px;
  height: 195.4px;
  border-radius: 20px;
  border: 1px solid #635b92;
}
.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: var(--bs-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: #ffd133;
  transition: var(--bs-progress-bar-transition);
}
.rank {
  margin-left: 214px;
  margin-top: -7px;
  background-repeat: no-repeat;
  background-image: url(../../public/image2_react/rank-bg.png);
}

.rank-t {
  font-size: 16px;
  margin-left: 13px;
  padding-top: 5px;
  color: white;
}

.rank-2 {
  color: white;
  margin-top: -10px;
  margin-left: 26px;
}

.review-profile1 {
  margin-top: -26px;
  margin-left: 33px;
  width: 151.775px;
  height: 155.4px;
  border-radius: 20px;
  border: 1px solid #5e549a;
}

.latest {
  border-radius: 50%;
  border: 1px solid #5e549a;
  margin-top: 11px;
  margin-left: 42px;
}

.goldProfile {
  margin-left: -22px;
  margin-top: 51px;
}

.profileName {
  color: #5ac4ff;
  font-size: 18px;
  font-weight: 600;
  margin-top: 10px;
  margin-left: 28px;
}

.review1 {
  display: flex;
  flex-direction: row;
  margin-left: 22px;
  margin-top: 12px;
}
.ProfileWinningRate {
  color: #00ff66;
  font-size: 24px;
  font-weight: 600;
  margin-left: 224px;
  margin-top: -126px;
}
.winningRate {
  color: #5ac4ff;
}
.profit {
  color: #b2b4e1;
}
.ProfileWinning {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border-right: 1px solid #5e549a;
  width: 108.863px;
  color: white;
  font-size: 18px;
  font-weight: 400;
  margin-left: 209px;
  margin-top: 15px;
}
.ProfileWinning2 {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border-right: 1px solid #5e549a;
  width: 108.863px;
  color: white;
  font-size: 18px;
  font-weight: 400;
  margin-left: 331px;
  margin-top: -59px;
}
.ProfileWinning3 {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border-right: 1px solid #5e549a;
  width: 108.863px;
  color: white;
  font-size: 18px;
  font-weight: 400;
  margin-left: 464px;
  margin-top: -59px;
}
.ProfileWinning4 {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border-right: 1px solid #5e549a;
  width: 108.863px;
  color: white;
  font-size: 18px;
  font-weight: 400;
  margin-left: 616px;
  margin-top: -59px;
}
.ProfileWinning5 {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border-right: 1px solid #5e549a;
  width: 108.863px;
  color: white;
  font-size: 18px;
  font-weight: 400;
  margin-left: 771px;
  margin-top: -59px;
}
.tenRight {
  justify-content: center;
  width: 163px;
  height: 161px;
  background-color: #1d1566;
  border-radius: 10px;
  text-align: center;
  margin-left: 1068px;
  margin-top: -140px;
  display: flex;
  flex-direction: column;
}
.tenRight1 {
  color: #00ff66;
  font-size: 24px;
  font-weight: 600;
  margin-top: -11px;
}
.tenRight2 {
  color: #b2b4e1;
  font-size: 16px;
  font-weight: 400;
}
.tenRight3 {
  color: white;
  background-color: #7924c7;
  margin-top: 24px;
  width: 122px;
  border-radius: 50px;
  margin-left: 21px;
}
.tenRight3:hover {
  background-color: transparent;
  color: white;
  border: 1px solid #7924c7;
}

.winningSecond {
  margin-top: 25px;
  width: 1264px;
  height: 195.4px;
  border-radius: 20px;
  border: 1px solid #635b92;
}
.winningThird {
  margin-top: 25px;
  width: 1264px;
  height: 195.4px;
  border-radius: 20px;
  border: 1px solid #635b92;
}
.winningForth {
  margin-top: 25px;
  width: 1264px;
  height: 195.4px;
  border-radius: 20px;
  border: 1px solid #635b92;
}
.winningFifth {
  margin-top: 25px;
  width: 1264px;
  height: 195.4px;
  border-radius: 20px;
  border: 1px solid #635b92;
}
.tenSectionButton {
  color: white;
  margin-top: 50px;
  border: 1px solid #5e549a;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 36px;
  padding-right: 35px;
  border-radius: 10px;
  margin-bottom: 150px;
}
.tenSectionButton:hover {
  background-color: #7924c7;
  color: white;
  border: 1px solid #7924c7;
}
.elevenSection {
  background-color: #2d0572;
  width: 100%;
}
.elevenFirstContact {
  margin-top: 100px;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.elevenLine1 {
  color: #ffd133;
  font-size: 24px;
  font-weight: 600;
  padding-top: 100px;
}
.elevenLine2 {
  font-size: 57px;
  font-weight: 700;
  padding-top: 45px;
}
.elevenLine3 {
  padding-top: 40px;
  font-size: 18px;
  font-weight: 400;
  padding-bottom: 300px;
}
.elevenLine4 {
  color: #ffd133;
}
.elevenSecondContact {
  background-image: linear-gradient(0deg, #070044 0%, #2d237b 99%);
  width: 1320px;
  display: flex;
  border-radius: 10px;
  flex-direction: column;
  align-items: center;
  margin: auto;
  margin-top: -232px;
  margin-bottom: 100px;
}
.faq_question_icon {
  background-image: url(../../public/image2_react/faq-icon.png);
  background-repeat: no-repeat;
  width: 30px;
  height: 30px;
}
.faq_question_container {
  width: 81%;
  background-color: #3a2fa4;
  box-shadow: 0px 12px 24px rgb(47 65 129 / 10%);
  border-radius: 10px;
  border: none;
  padding: 20px 20px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}
.faq_question {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.faq_question p {
  margin: 0;
}
.faq_question_text {
  background-color: #3a2fa4;
  border: none;
  color: white;
  font-size: 26px;
  font-weight: 400;
}
.faq_answer {
  overflow: hidden;
  transition: max-height 0.3s linear;
}
.faq_answer hr {
  color: white;
  opacity: 0.5;
}
.faq_answer p {
  margin: 0;
  color: white;
  font-weight: 100;
  opacity: 0.8;
  font-size: 16px;
}
.elevenSecondEnd {
  width: 30px;
  height: 30px;
  background: #261c7e;
  border-radius: 5px;
}
.plus {
  background-image: url(../../public/image2_react/plus.png);
  background-repeat: no-repeat;
  background-position: center center;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background-color: #261c7e;
  border: none;
}
.elevenSecondContact2 {
  width: 1076px;
  height: 71.2px;
  margin-top: 15px;
  background-color: #3a2fa4;
  box-shadow: 0px 12px 24px rgb(47 65 129 / 10%);
  border-radius: 10px;
  border: none;
  position: relative;
  display: flex;
  align-items: center;
}
.elevenSecondContact3 {
  width: 1076px;
  height: 71.2px;
  margin-top: 15px;
  background-color: #3a2fa4;
  box-shadow: 0px 12px 24px rgb(47 65 129 / 10%);
  border-radius: 10px;
  border: none;
  position: relative;
  display: flex;
  align-items: center;
}
.elevenSecondContact4 {
  width: 1076px;
  height: 71.2px;
  margin-top: 15px;
  background-color: #3a2fa4;
  box-shadow: 0px 12px 24px rgb(47 65 129 / 10%);
  border-radius: 10px;
  border: none;
  position: relative;
  display: flex;
  align-items: center;
}
.elevenSecondContact5 {
  width: 1076px;
  height: 71.2px;
  margin-top: 15px;
  background-color: #3a2fa4;
  box-shadow: 0px 12px 24px rgb(47 65 129 / 10%);
  border-radius: 10px;
  border: none;
  position: relative;
  display: flex;
  align-items: center;
}
.elevenSecondContact6 {
  width: 1076px;
  height: 71.2px;
  margin-top: 15px;
  background-color: #3a2fa4;
  box-shadow: 0px 12px 24px rgb(47 65 129 / 10%);
  border-radius: 10px;
  border: none;
  position: relative;
  display: flex;
  align-items: center;
}
.elevenSecondContact7 {
  width: 1076px;
  height: 71.2px;
  margin-top: 15px;
  background-color: #3a2fa4;
  box-shadow: 0px 12px 24px rgb(47 65 129 / 10%);
  border-radius: 10px;
  border: none;
  position: relative;
  display: flex;
  align-items: center;
}
.twelveSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.twelveSectionL1 {
  color: #ffd133;
  font-size: 24px;
  font-weight: 600;
  padding-top: 50px;
}
.twelveSectionL2 {
  padding-top: 40px;
  padding-bottom: 40px;
  font-size: 57px;
  font-weight: 700;
  color: white;
}
.twelveSectionL3 {
  color: white;
  font-size: 18px;
  font-weight: 400;
  padding-bottom: 100px;
}
.twelveSection2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-bottom: 350px;
}
.twelveSectionLeft {
  background-color: #2d0572;
  width: 648px;
  height: 225px;
  margin-right: 25px;
  border-radius: 10px;
}

.twelveSectionLeftL1 {
  color: white;
  font-size: 24px;
  font-weight: 400;
  margin-top: 35px;
  margin-left: 33px;
}
.twelveSectionLeftL2 {
  color: white;
  font-size: 24px;
  font-weight: 400;
  margin-left: 33px;
}
.twelveImg1 {
  border-radius: 50%;
  margin-top: 30px;
  margin-left: 32px;
}
.twelveName {
  color: white;
  margin-top: -55px;
  margin-left: 106px;
}
.twelveCity {
  color: #ff96a5;
  margin-left: 106px;
  font-size: 16px;
  font-weight: 400;
}
.twelveSectionRight {
  background-color: #2d0572;
  width: 648px;
  height: 225px;
  border-radius: 10px;
}

.footerSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: -221px;
  width: 1076px;
  height: 426.8px;
  background-image: url(../../public/image2_react/newsletter-left.png),
    url(../../public/image2_react/newsletter-right.png);
  background-repeat: no-repeat, no-repeat;
  background-position: left bottom, right bottom;
  background-color: #2d237b;
  border-radius: 15px;
  padding: 80px 260px;
}
.footerSL1 {
  color: white;
  font-size: 43px;
  font-weight: 700;
}
.footerSL2 {
  color: white;
  font-size: 43px;
  font-weight: 700;
  margin-top: 30px;
}
.footerSL3 {
  color: white;
  margin-top: 30px;
  font-size: 18px;
  font-weight: 400;
}
.textLine {
  display: flex;
  flex-direction: row;
}

.textLine1 {
  padding-left: 35px;
  margin-top: 50px;
  background-color: #1d1566;
  border: 1px solid #51478e;
  border-radius: 30px;
  width: 554px;
  height: 60px;
}
/* .sendIcon{
  border-radius: 50%;
  background-color: #7924c7;
  width: 40px;
  height: 40px;
  margin-top: 60px;
  margin-left: -57px;
} */
.send-icon1 {
  border: none;
  border-radius: 50%;
  background-color: #7924c7;
  width: 40px;
  height: 40px;
  margin-top: 60px;
  margin-left: -57px;
}
.lastFooter {
  width: 100%;
  background-color: #2d0572;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.footer {
  width: 85%;
  padding: 25px 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}


.footerLogo {
  /* width: 129px; */
  flex: 0.3;
  object-fit: contain;
}
.footer-line {
  color: white;
  display: flex;
  flex-direction: row;
  list-style: none;
  flex: .7;
  justify-content: space-between;
  margin: 0;
}
.footer-link {
  text-decoration: none;
  color: white;
  white-space: nowrap;
}
.copyright-area {
  width: 85%;
  height: 95px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #070044;
  border-radius: 10px 10px 0px 0px;
}
.copyright {
  color: white;
  margin-left: 38px;
}
.Copy {
  color: #ffd133;
}
.pixel {
  color: #ff96a5;
}
